<!--
 * @Description: 法定节假日
 * @Author: 琢磨先生
 * @Date: 2022-05-27 21:39:19
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-02 10:52:14
-->

<template>
  <el-card class="box">
    <el-select v-model="query.year" placeholder="" @change="yearChange">
      <el-option
        :label="item.text"
        :value="item.value"
        v-for="item in years"
        :key="item.text"
      ></el-option>
    </el-select>
    <el-button
      type="primary"
      style="margin-left: 20px"
      @click="onEdit"
      round
      icon="plus"
      >设置</el-button
    >
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-scrollbar>
      <el-empty description="暂未设置" v-if="list.length == 0" />
      <el-card shadow="never" class="item" v-for="item in list" :key="item.id">
        <div class="title">{{ item.type_text }}</div>
        <span class="date">{{ item.start_date }}-{{ item.end_date }}</span>
      </el-card>
    </el-scrollbar>
  </el-card>
  <el-drawer
    v-model="drawer"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    size="800px"
    :show-close="false"
  >
    <el-card
      class="item"
      shadow="never"
      v-for="item in types"
      :key="item.value"
    >
      <div class="title">{{ item.text }}</div>
      <el-date-picker
        v-model="item.dates"
        type="daterange"
        :disabled-date="disabledDate"
        value-format="YYYY-MM-DD"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
      />
    </el-card>
    <template #footer>
      <div style="flex: auto">
        <el-button type="primary" @click="confirmClick" :loading="saving"
          >确定</el-button
        >
        <el-button @click="cancelClick">取消</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script>
import common_api from "@/http/common_api";
export default {
  data() {
    return {
      loading: false,
      saving: false,
      query: {
        year: 0,
      },
      years: [],
      list: [],
      drawer: false,
      title: "",
      types: [],
    };
  },
  created() {
    var date = new Date();
    var year = date.getFullYear();
    this.query.year = year;
    for (var i = 0; i < 2; i++) {
      this.years.push({
        text: year + i + "年",
        value: year + i,
      });
    }

    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.types = res.data.holiday_types;
      }
    });
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.get(`admin/v1/holiday?year=${this.query.year}`).then((res) => {
        if (res.code == 0) {
          this.list = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 年份修改
     */
    yearChange(val) {
      this.query.year = val;
      this.loadData();
    },
    /**
     *
     */
    onEdit() {
      this.drawer = true;
      this.title = `设置${this.query.year}年节假日`;
    },
    /**
     *
     * @param {*} date
     */
    disabledDate(date) {
      return this.query.year != date.getFullYear();
    },

    /**
     * 提交保存
     */
    confirmClick() {
      console.log(this.types);
      var list = this.types.filter((o) => !o.dates);
      if (list.length > 0) {
        var item = list[0];
        this.$message({
          type: "warning",
          message: `请设置${item.text}日期`,
        });
        return;
      }

      var data = [];

      this.types.forEach((item) => {
        data.push({
          year: this.query.year,
          type: item.value,
          start_date: item.dates[0],
          end_date: item.dates[1],
        });
      });
      this.saving = true;
      this.$http
        .post("admin/v1/holiday/edit", data)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.loadData();
            this.drawer = false;
          }
          this.saving = false;
        })
        .error(() => {
          this.saving = false;
        });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
    },
  },
};
</script>

<style  scoped>
.item {
  margin-bottom: 20px;
}
.item .title {
  margin-bottom: 10px;
}
.item .date {
  color: #999;
}
</style>